import "./bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";

import Toastr from "toastr2";

let toastr = new Toastr();
toastr.options.preventDuplicates = true;
toastr.options.closeButton = true;

AOS.init();

window.index = function (instant_quote) {
    if (instant_quote === 'yes')
    {
        setTimeout(function () {
            document.querySelector('.menu-link-instant-quote').click();
        }, 1000);
    }
}

window.show_loading_button = function (button, text) {
    button.innerHTML = '<i class="fas fa-circle-notch fa-spin mr-2"></i> ' + text;
}

window.global = function () {

    return {

        mobile_menu_open: false,
        domain: 'https://tpuserportal.com',
        // domain: 'https://c49f-71-121-147-194.ngrok.io',
        auto_slide: true,
        activeSlide: 1,
        slides: [1, 2],
        index_695_modal: false,
        show_email_staff_modal: false,
        show_google_review_request: false,
        show_thank_you: false,

        init() {
            let scope = this;
            scope.shrink_menu_on_scroll();

            setTimeout(function () {
                scope.show_qualia();
            }, 2000);
            window.addEventListener("resize", function () {
                setTimeout(function () {
                    scope.show_qualia();
                }, 2000);
            });

            setInterval(function () {
                scope.format_phones();
            }, 1000);


            setTimeout(function () {
                scope.slider();
            }, 3000);

            let scrolled = false;
            document.addEventListener('scroll', (e) => {
                if (scrolled == false)
                {
                    scrolled = true;
                    setTimeout(function() {
                        scope.show_index_695_modal();
                    }, 5000);
                }
            });

            setTimeout(function() {
                scope.stars();
                // document.querySelectorAll('.star-rating').forEach(function (star) {
                //     star.classList.remove('hidden');
                // });
                // scope.numbers();
            }, 500);

        },

        show_index_695_modal() {
            let scope = this;
            if (window.location.pathname == '/')
            {
                setTimeout(function () {
                    scope.index_695_modal = true;
                }, 1000);
            }

        },

        stars() {
            let stars_rating = new StarRating('.star-rating-stars', { tooltip: 'Please Select' });
            let stars_likely = new StarRating('.star-rating-likely', { tooltip: 'Please Select' });
            let stars_satisfied = new StarRating('.star-rating-satisfied', { tooltip: 'Please Select' });
        },

        // numbers() {
        //     let scope = this;
        //     document.querySelectorAll('.button-group').forEach(function (button_div) {
        //         button_div.querySelectorAll('label').forEach(function (label) {
        //             label.addEventListener('mouseenter', function () {
        //                 let value = label.querySelector('input').value;
        //                 button_div.querySelectorAll('label').forEach(function (label) {
        //                     label.classList.remove('active');
        //                     let input = label.querySelector('input');
        //                     if (input.value <= value)
        //                     {
        //                         label.classList.add('active');
        //                         label.querySelector('i').classList.add('fa-solid');
        //                         label.querySelector('i').classList.remove('fa-regular');
        //                     } 
        //                 });
        //             });
        //             label.addEventListener('mouseleave', function () {
        //                 scope.update_rating(button_div);
        //             });
        //         });
        //     });
        // },

        update_rating(button_div) {
            
            let checked = button_div.querySelector('[name="ratings[]"]:checked');
            let value = 0;
            if (checked)
            {
                value = checked.value
            }
            
            button_div.setAttribute('data-value', value);
            button_div.querySelectorAll('label').forEach(function (label) {
                label.classList.remove('active');
                label.querySelector('i').classList.remove('fa-solid');
                label.querySelector('i').classList.add('fa-regular');
                let input = label.querySelector('input');
                if (input.value <= value)
                {
                    label.classList.add('active');
                    label.querySelector('i').classList.add('fa-solid');
                    label.querySelector('i').classList.remove('fa-regular');
                }
            });
        },

        save_survey(ele) {

            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Submitting Survey ... ');
            
            let form = scope.$refs.survey_form;
            let formData = new FormData(form);
            
            axios.post('/survey/save_survey', formData)
            .then(function (response) {
                ele.innerHTML = button_html;
                scope.$refs.form_div.classList.add('hidden');
                
                scope.$refs.thank_you_div.classList.remove('hidden');
                window.scrollTo({ top: 0, behavior: 'smooth' });

                // show google review request if over 4 stars
                let ratings = document.querySelectorAll('[name="ratings[]"]');
                let count = ratings.length;
                let total = 0;
                ratings.forEach(function (rating) {
                    total += parseInt(rating.value);
                });
                
                let average = total / count;
                
                if (average >= 4)
                {
                    console.log('working');
                    
                    scope.show_google_review_request = true;
                }
            })
            .catch(function (error) {
            });

        },

        
        slider() {
            let scope = this;
            setInterval(() => {
                if (scope.auto_slide === true)
                {
                    scope.flip_slide();
                    scope.activeSlide = scope.activeSlide === scope.slides.length ? 1 : scope.activeSlide + 1;
                }
            }, 10000);
        },

        flip_slide() {

            let scope = this;
            // scope.$refs.slide.classList.remove('animate__fadeIn');
            // scope.$refs.slide.classList.add('animate__fadeOut');
            // setTimeout(function () {
            //     scope.$refs.slide.classList.remove('animate__fadeOut');
            //     scope.$refs.slide.classList.add('animate__fadeIn');
            // }, 500);
            if (scope.$refs.slide)
            {
                scope.$refs.slide.classList.add('animate__fadeOut');
                scope.$refs.slide.classList.add('animate__flipInX');
                setTimeout(function () {
                    scope.$refs.slide.classList.remove('animate__fadeOut');
                    scope.$refs.slide.classList.remove('animate__flipInX');
                }, 1000);
            }


        },

        show_loading_button(button, text) {
            button.innerHTML =
                '<i class="fas fa-circle-notch fa-spin mr-2"></i> ' + text;
        },

        shrink_menu_on_scroll() {
            let scope = this;
            let menu_links = document.querySelectorAll(".menu-link");
            window.onscroll = function () {
                if (
                    document.body.scrollTop > 50 ||
                    document.documentElement.scrollTop > 50
                )
                {
                    menu_links.forEach(function (link) {
                        link.classList.add("shrink");
                    });
                    if (scope.$refs.logo)
                    {
                        scope.$refs.logo.classList.add("shrink");
                        scope.$refs.header.classList.add("shrink");
                    }
                    scope.$refs.header_div.classList.add("shrink");
                    scope.$refs.quote_button_nav.classList.add("shrink");
                } else
                {
                    menu_links.forEach(function (link) {
                        link.classList.remove("shrink");
                    });
                    if (scope.$refs.logo)
                    {
                        scope.$refs.logo.classList.remove("shrink");
                        scope.$refs.header.classList.remove("shrink");
                    }
                    scope.$refs.header_div.classList.remove("shrink");
                    scope.$refs.quote_button_nav.classList.remove("shrink");
                }
            };
        },

        show_qualia() {
            if (app_env == "production")
            {
                if (document.documentElement.clientWidth < 600)
                {
                    document.querySelector("#iFrameResizer0").style.display =
                        "none";
                } else
                {
                    document.querySelector("#iFrameResizer0").style.display =
                        "inline-block";
                }
            }
        },

        format_phone(obj) {
            if (obj)
            {
                let numbers = obj.value.replace(/\D/g, ""),
                    char = {
                        0: "(",
                        3: ") ",
                        6: "-",
                    };
                obj.value = "";
                for (let i = 0; i < numbers.length; i++)
                {
                    if (i > 13)
                    {
                        return false;
                    }
                    obj.value += (char[i] || "") + numbers[i];
                }
            }
        },

        format_phones() {
            let scope = this;
            document.querySelectorAll(".phone").forEach(function (input) {
                input.classList.add("numbers-only");
                scope.format_phone(input);
                input.setAttribute("maxlength", 14);
                input.addEventListener("keyup", (event) => {
                    scope.format_phone(input);
                });
            });
        },

        numbers_only() {
            document
                .querySelectorAll(".numbers-only")
                .forEach(function (input) {
                    input.addEventListener("keydown", (event) => {
                        let allowed_keys = [
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "0",
                            ",",
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                            "Control",
                            "v",
                            "c",
                        ];

                        if (!input.classList.contains("no-decimals"))
                        {
                            allowed_keys.push(".");
                        }
                        if (!allowed_keys.includes(event.key))
                        {
                            event.preventDefault();
                        } else
                        {
                            let max = input.getAttribute("max") || null;

                            if (max)
                            {
                                if (parseInt(input.value + event.key) > max)
                                {
                                    event.preventDefault();
                                    input.value = event.key;
                                }
                            }
                        }
                    });
                });
        },

        display_errors(error, ele, button_html) {
            if (error)
            {
                if (error.response)
                {
                    if (error.response.status == 422)
                    {
                        let errors = error.response.data.errors;
                        this.show_form_errors(errors);
                        ele.innerHTML = button_html;
                        console.log(button_html);
                    }
                }
            }
        },

        show_form_errors(errors) {
            this.remove_form_errors();

            Object.entries(errors).forEach(([key, value]) => {
                let field = `${key}`;
                value = `${value}`;
                let message = value;
                if (Array.isArray(value))
                {
                    message = value[0];
                }

                let element = "";
                if (field.match(/\.[0-9]+$/))
                {
                    let fields = field.split(".");
                    let elements = document.querySelectorAll(
                        '[name="' + fields[0] + '[]"]'
                    );
                    element = elements[fields[1]];
                } else
                {
                    element = document.querySelector('[name="' + field + '"]');
                }
                let label = "";
                let error_message =
                    '<div class="error-message text-red-400 text-xs mt-1">' +
                    message +
                    "</div>";

                if (element)
                {
                    if (element.parentNode.tagName == "LABEL")
                    {
                        label = element.parentNode;
                        label.insertAdjacentHTML("beforeend", error_message);
                    } else
                    {
                        label = element.parentNode.querySelector("label");
                        element.parentNode.insertAdjacentHTML(
                            "beforeend",
                            error_message
                        );
                        element.classList.add("input-error");
                    }

                    //let error_message = element.closest('label').querySelector('.error-message');
                    //error_message.innerHTML = message;
                    this.scroll_above(element);
                    toastr.error("Field not completed");
                }
            });
        },

        remove_form_errors(event = null) {
            if (event)
            {
                let label = event.target.closest("label");
                label.querySelector(".error-message").innerHTML = "";
                label
                    .querySelector(".error-message")
                    .classList.toggle("hidden");
            } else
            {
                document
                    .querySelectorAll(".error-message")
                    .forEach(function (error_div) {
                        error_div.remove();
                    });
                document
                    .querySelectorAll(".input-error")
                    .forEach(function (input) {
                        input.classList.remove("input-error");
                    });
            }
        },

        scroll_above(element) {
            let yOffset = -450;
            let y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;
            window.scrollTo({
                top: y,
                behavior: "smooth",
            });
            element.focus({
                preventScroll: true,
            });
        },

        get_url_parameters(key) {
            // usage
            // let tab = global_get_url_parameters('tab');
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.has(key))
            {
                return urlParams.get(key);
            }
            return false;
        },

        show_email_staff(id, name) {

            let scope = this;

            scope.show_email_staff_modal = true;

            scope.$refs.first_name.innerText = name;
            scope.$refs.id.value = id;

        },

        email_staff(ele, form) {

            let scope = this;
            let button_html = ele.innerHTML;
            scope.show_loading_button(ele, 'Sending Message ... ');
            scope.remove_form_errors();

            let formData = new FormData(form);

            axios.post('/email_staff', formData)
                .then(function (response) {
                    ele.innerHTML = button_html;
                    scope.show_email_staff_modal = false;
                    toastr.success(
                        'Your message has been successfully submitted'
                    );
                    form.reset();
                })
                .catch(function (error) {
                    scope.display_errors(error, ele, button_html);
                });
        }


    };
}
